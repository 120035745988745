import { SHOP_LIMIT_HEADERS } from "./voucher-tables";
import moment from "moment";
import { COMMON_DATE_FORMATS } from "@/common/date-format";

export default {
  name: "voucher-limitations-shop",
  data() {
    return {
      dataSource: [
        {
          name: "Test",
          validFrom: "2024-02-12",
          validUntil: "2024-03-12",
          limitSet: 1,
          spent: 1,
          left: 1
        }
      ],
      dataSourceArchive: []
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format(COMMON_DATE_FORMATS.FULL_MONTH);
    }
  },
  computed: {
    headers() {
      return SHOP_LIMIT_HEADERS({ i18n: this.$i18n });
    }
  }
};
