var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header border-0 pt-2"},[_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"header-name h2"},[_vm._v("Shop name")]),_c('div',{staticClass:"header-inner"},[_c('div',{staticClass:"header-inner__item header-inner__item--big header-inner__item--active-limits"},[_c('div',{staticClass:"header-inner__item__title"},[_vm._v(" "+_vm._s(_vm.$t("commonTable.activeLimitsSum"))+" ")]),_c('div',{staticClass:"header-inner__item__value"},[_vm._v("$ 1200")])]),_vm._m(0),_vm._m(1),_vm._m(2)])]),_c('div',{staticClass:"elevation-2 p-2 mt-4 rounded white"},[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"title mb-2"},[_vm._v("Active limits")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataSource,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.validFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.validFrom))+" ")]}},{key:"item.validUntil",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.validUntil))+" ")]}},{key:"item.limitSet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.limitSet)+" ")]}},{key:"item.spent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.spent)+" ")]}},{key:"item.left",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.left)+" ")]}}])})],1),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"title mb-2"},[_vm._v("Archive of limits")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataSource,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.validFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.validFrom))+" ")]}},{key:"item.validUntil",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.validUntil))+" ")]}},{key:"item.limitSet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.limitSet)+" ")]}},{key:"item.spent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.spent)+" ")]}},{key:"item.left",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.left)+" ")]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-inner__item header-inner__item--big header-inner__item--left-limits"},[_c('div',{staticClass:"header-inner__item__title"},[_vm._v("Left limits Summ")]),_c('div',{staticClass:"header-inner__item__value text-primary"},[_vm._v("$ 1010")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-inner__item header-inner__item--spend-month"},[_c('div',{staticClass:"header-inner__item__title"},[_vm._v("Spent for last month")]),_c('div',{staticClass:"header-inner__item__value "},[_vm._v("$ 584")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-inner__item header-inner__item--spend-year"},[_c('div',{staticClass:"header-inner__item__title"},[_vm._v("Spent for last year")]),_c('div',{staticClass:"header-inner__item__value"},[_vm._v("$ 1680")])])}]

export { render, staticRenderFns }